import React from 'react';
import PropTypes from 'prop-types';

const MenuLink = ({ linkName, section, func, id }) => {
	return (
		<a
			className={section === linkName ? 'active first' : 'first'}
			onClick={() => func(linkName.toLowerCase().replace(' ', '-'))}
			// href={`#${linkName.toLowerCase().replace(' ', '-')}`}
			id={id}>
			{linkName}
		</a>
	);
};

MenuLink.propTypes = {
	func: PropTypes.func,
	linkName: PropTypes.string.isRequired,
	section: PropTypes.string.isRequired,
	id: PropTypes.string,
};

export default MenuLink;
