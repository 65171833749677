import { RotatingLines, ThreeDots } from 'react-loader-spinner';
import { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

import pageLogoAnimation from 'assets/icons/loader.json';
import styles from './loaders.module.scss';

const PageLoader = () => {
	const ref = useRef(null);

	useEffect(() => {
		lottie.loadAnimation({
			container: ref.current,
			renderer: 'svg',
			animationData: pageLogoAnimation,
			loop: true,
			autoplay: true,
		});

		return lottie.destroy;
	}, []);

	return (
		<div className={styles.container}>
			<div ref={ref} className={styles.content} />
		</div>
	);
};

const CircleLoader = () => {
	return (
		<div className={styles.CircleCont}>
			<ThreeDots
				height='80'
				width='80'
				radius='9'
				color='var(--primary)'
				ariaLabel='three-dots-loading'
				wrapperStyle={{}}
				visible={true}
			/>
		</div>
	);
};

// eslint-disable-next-line react/prop-types
const ImageLoader = ({ color, width }) => {
	return (
		<div className={styles.ImageCont}>
			<RotatingLines
				strokeColor={color || 'var(--primary)'}
				strokeWidth='5'
				animationDuration='0.75'
				width={width || '36'}
				visible={true}
			/>
		</div>
	);
};

const Loader = {
	PageLoader,
	CircleLoader,
	ImageLoader,
};

export default Loader;
