import React from 'react';
import PropTypes from "prop-types";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = ({ alt, width, height, src, className, onClick }) => {
	return (
		<LazyLoadImage
			alt={alt}
			effect='blur'
			loading='lazy'
			src={src}
			width={width || '100%'}
			height={height || '100%'}
			className={className}
			onClick={() => onClick?.()}
		/>
	);
};

LazyImage.propTypes = {
	alt: PropTypes.string.isRequired,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	src: PropTypes.string.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
  };


export default LazyImage;
