import React from 'react';
import { createRoot } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

import reportWebVitals from './tests/reportWebVitals';
import './styles/index.scss';
import App from './app';
import env from 'configs/env.config.json';

const root = document.getElementById('root');
const rootContainer = createRoot(root);

const id = env.GOOGLE_CLIENT_ID_OFFICIAL;

if (root.hasChildNodes()) {
	rootContainer.hydrate(
		<React.StrictMode>
			<GoogleOAuthProvider clientId={id}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</GoogleOAuthProvider>
		</React.StrictMode>
	);
} else {
	rootContainer.render(
		<React.StrictMode>
			<GoogleOAuthProvider clientId={id}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</GoogleOAuthProvider>
		</React.StrictMode>
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
