import { Outlet } from 'react-router-dom';
import React, { Suspense } from 'react';


import styles from './HackathonLayout.module.scss';
import Loader from 'components/loaders';
import Navbar from "components/navbar/HackathonNavbar";

function HackathonLayout() {

	const links = ["About", "Problem Sector", "Schedule", "FAQs"];

	return (
		<Suspense fallback={<Loader.PageLoader />}>
			<Navbar links = {links} btnName="Register" btnClassName='hackaThon'/>
			<main className={styles.main}>
				<Suspense fallback={<Loader.PageLoader />}>
					<Outlet />
				</Suspense>
			</main>
			
		</Suspense>
	);
} 



export default HackathonLayout;
